'use client'
import 'reflect-metadata'

import Background from '@/app/_components/Shared/Background'
import LoginForm from '@/app/_components/Shared/Entry/components/LoginForm'
import SocialCircleButtons from '@/app/_components/Shared/SocialCircleButtons'
import { useI18n } from '@/locales/client'
import SignBackground from '@/public/bg9__3xl_2xl.svg'
import Link from 'next/link'

export default function LoginPage(props: {
  searchParams: {
    error?: string
  }
}) {
  const t = useI18n()

  return (
    <Background
      imgSrc={SignBackground}
      imgAlt="signin bg"
      bgColor="bg-french-bleu">
      <div className="container relative flex items-center justify-center pt-90 lg:pt-82">
        <div className="relative flex w-496 py-67">
          <div className="relative flex w-full flex-col gap-60 max-xl:gap-40">
            <LoginForm error={props.searchParams.error || null} />
            <div className="relative flex flex-col items-center gap-15 max-xl:gap-20">
              <SocialCircleButtons />
              <div className="c-h7 flex gap-8">
                <div className="text-[#80A8E7]">
                  {t('common.notRegYetLabel')}
                </div>
                <Link
                  href="/signup"
                  className="text-butterscotch underline underline-offset-2">
                  {t('common.signUpLabel')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Background>
  )
}
