import { SocialTypeEnumSchema } from '@/backend/models/Social'
import { z } from 'zod'

export const CredentialsSchema = z.object({
  email: z.string().email(),
  password: z.string(),
})

export type Credentials = z.infer<typeof CredentialsSchema>

export const SocialCredentialsSchema = z.object({
  type: SocialTypeEnumSchema,
  socialId: z.string(),
  email: z.string().email(),

  displayName: z.string().optional(),
})

export type SocialCredentials = z.infer<typeof SocialCredentialsSchema>
