'use client'

/* eslint-disable react-hooks/exhaustive-deps */

import CheckBoxChecked from '@/app/_components/Icon/CheckBoxChecked'
import Warning from '@/app/_components/Icon/Warning'
import { Button } from '@/app/_primitives/Button'
import { toast } from '@/app/_primitives/Toast'
import { signIn } from '@/auth/client/signIn'
import { CredentialsSchema } from '@/backend/models/Credentials'
import { useI18n } from '@/locales/client'
import { zodResolver } from '@hookform/resolvers/zod'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type FormData = { email: string; password: string }

export default function LoginForm({ error }: { error: string | null }) {
  const searchParams = useSearchParams()

  const t = useI18n()
  const [isSaveID, setIsSaveId] = useState(true)

  useEffect(() => {
    if (error) {
      // NOTE: possible errors
      // Email errors
      // USER_NOT_FOUND, INCORRECT_PASSWORD
      //
      // Social errors
      // INVALID_STATE
      // SOCIAL_SIGNIN_FAILED
      // SOMETHING_WENT_WRONG
      //
      setTimeout(() => {
        toast.error({
          title: '아이디 또는 비밀번호가 일치하지 않습니다',
          content: (
            <>
              가입한 계정정보가 기억이 나지 않을 경우
              <br /> 아이디/비밀번호 찾기를 이용해 주세요.
            </>
          ),
        })
      }, 100)
    }
  }, [error])

  const handleSaveId = () => {
    setIsSaveId((prev) => !prev)
  }

  const {
    register,
    formState,
    formState: { errors },
    handleSubmit,
    // watch,
  } = useForm<FormData>({
    resolver: zodResolver(CredentialsSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onBlur',
  })

  const handleValid = async (data: FormData) => {
    await signIn({
      provider: 'credentials',
      email: data.email,
      password: data.password,
      callbackUrl: searchParams.get('callbackUrl') ?? '/',
    })
  }

  return (
    <form className="w-full text-center">
      <div className="b-h2 mb-60 text-white max-xl:b-h3 max-xl:mb-40">
        {t('common.signInLabel')}
      </div>
      {/* {error && (
        <p className="flex items-center justify-center text-center text-[14px] font-[500] leading-[22.4px] text-[#FF9840]">
          <Warning fill="#FF9840" />
          &nbsp;아이디 랑 비밀번호 채크해주세요.
        </p>
      )} */}
      <div className="relative flex w-full flex-col gap-12 xl:gap-16">
        <div>
          <input
            className="h-60 w-full  rounded-2xl bg-white/80 px-21 text-base font-medium leading-26 placeholder:text-gray lg:px-31"
            placeholder={'innie@inabooth.com'}
            {...register('email')}
          />
          {errors.email && (
            <p className="flex items-center text-start text-14 font-[500] leading-[22.4px] text-orancia">
              <Warning fill="#FF9840" />
              &nbsp;{t('common.emailFormatErr')}
            </p>
          )}
        </div>
        <input
          className="h-60 w-full  rounded-2xl bg-white/80 px-21 text-base font-medium leading-26 placeholder:text-gray lg:px-31"
          placeholder={'*******'}
          type="password"
          {...register('password')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(handleValid)()
            }
          }}
        />
        <div className="relative flex w-full justify-between text-white">
          <div className="c-b4 flex items-center gap-8 xl:c-b3">
            <button
              type="button"
              className="size-20 cursor-pointer"
              onClick={handleSaveId}>
              {isSaveID ? (
                <CheckBoxChecked fill="#FDCC53" />
              ) : (
                <div className="relative size-20 rounded-sm border-[1.5px] border-white" />
              )}
            </button>
            <div>{t('common.saveIdLabel')}</div>
          </div>
          <Link className="c-b4 xl:c-b3" href="/forgot-password">
            {t('common.findIdPwdLabel')}
          </Link>
        </div>
      </div>
      <Button
        disabled={formState.isSubmitting}
        loading={formState.isLoading || formState.isSubmitting}
        type="button"
        className="mt-20 hidden w-full lg:block"
        color="butterScotch"
        size="lg"
        onClick={handleSubmit(handleValid)}>
        <div className="text-french-bleu">{t('common.signInLabel')}</div>
      </Button>
      <Button
        disabled={formState.isSubmitting}
        loading={formState.isLoading || formState.isSubmitting}
        type="button"
        className="mt-20 block w-full lg:hidden"
        color="butterScotch"
        onClick={handleSubmit(handleValid)}>
        <div className="c-h7 text-french-bleu">{t('common.signInLabel')}</div>
      </Button>
    </form>
  )
}
