import { SvgIconProps } from '@/types/helpers'

export default function CheckBoxChecked(props: SvgIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect width="20" height="20" rx="2" fill="currentFill" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.776 6.20974C17.0747 6.4894 17.0747 6.94281 16.776 7.22246L10.2104 13.3708C9.31451 14.2097 7.86196 14.2097 6.96605 13.3708L4.22398 10.803C3.92534 10.5233 3.92534 10.0699 4.22398 9.79026C4.52261 9.5106 5.0068 9.5106 5.30543 9.79026L8.04751 12.3581C8.34614 12.6377 8.83033 12.6377 9.12896 12.3581L15.6946 6.20974C15.9932 5.93009 16.4774 5.93009 16.776 6.20974Z"
        fill={props.fill ? 'white' : 'black'}
      />
    </svg>
  )
}
